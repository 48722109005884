import React from "react";

const Logout = ({
  fill, size
}) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      id="icon/action/exit_to_app_24px"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.1314 0.283936H3.13982C1.56906 0.283936 0.28389 1.56911 0.28389 3.13987V23.1314C0.28389 24.7022 1.56906 25.9873 3.13982 25.9873H23.1314C24.7021 25.9873 25.9873 24.7022 25.9873 23.1314V18.8475C25.9873 18.0621 25.3447 17.4195 24.5593 17.4195C23.7739 17.4195 23.1314 18.0621 23.1314 18.8475V21.7034C23.1314 22.4888 22.4888 23.1314 21.7034 23.1314H4.56779C3.78241 23.1314 3.13982 22.4888 3.13982 21.7034V4.56784C3.13982 3.78246 3.78241 3.13987 4.56779 3.13987H21.7034C22.4888 3.13987 23.1314 3.78246 23.1314 4.56784V7.42377C23.1314 8.20915 23.7739 8.85174 24.5593 8.85174C25.3447 8.85174 25.9873 8.20915 25.9873 7.42377V3.13987C25.9873 1.56911 24.7164 0.283936 23.1314 0.283936ZM7.7236 14.1351L12.85 19.2615C13.4069 19.8185 14.3065 19.8185 14.8634 19.2615C15.4061 18.7189 15.4203 17.805 14.8634 17.2481L12.1789 14.5635H24.5593C25.3447 14.5635 25.9873 13.9209 25.9873 13.1356C25.9873 12.3502 25.3447 11.7076 24.5593 11.7076H12.1789L14.8634 9.00874C15.4203 8.45183 15.4203 7.55222 14.8634 6.99531C14.5967 6.72792 14.2344 6.57765 13.8567 6.57765C13.479 6.57765 13.1168 6.72792 12.85 6.99531L7.7236 12.1217C7.1667 12.6786 7.1667 13.5782 7.7236 14.1351Z"
      fill="white"
    />
  </svg>
);

export default Logout;
