// event actions
export const FETCH_EVENTS_REQUEST = "FETCH_EVENTS_REQUEST";
export const FETCH_EVENTS_SUCCESS = "FETCH_EVENTS_SUCCESS";
export const FETCH_EVENTS_ERROR = "FETCH_EVENTS_ERROR";

// user actions
export const FETCH_USER_REQUEST = "FETCH_USER_REQUEST";
export const FETCH_USER_SUCCESS = "FETCH_USER_SUCCESS";
export const FETCH_USER_ERROR = "FETCH_USER_ERROR";
export const SET_USER = "SET_USER";
export const FETCH_USER_REGISTERED_EVENTS_REQUEST =
  "FETCH_USER_REGISTERED_EVENTS_REQUEST";
export const FETCH_USER_REGISTERED_EVENTS_SUCCESS =
  "FETCH_USER_REGISTERED_EVENTS_SUCCESS";
export const FETCH_USER_REGISTERED_EVENTS_ERROR =
  "FETCH_USER_REGISTERED_EVENTS_ERROR";

// membership actions
export const FETCH_MEMBERSHIPS_REQUEST = "FETCH_MEMBERSHIPS_REQUEST";
export const FETCH_MEMBERSHIPS_SUCCESS = "FETCH_MEMBERSHIPS_SUCCESS";
export const FETCH_MEMBERSHIPS_ERROR = "FETCH_MEMBERSHIPS_ERROR";

export const LOGOUT = "LOGOUT";
